.App {
  text-align: center;
  flex-direction: column;
  background-color: black;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  align-content: center;
  background-image: url("https://images.pexels.com/photos/1661002/pexels-photo-1661002.jpeg");
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Avatar {
  margin-top: 65px;
  margin-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
  max-height: 30vh;
  max-width: 90vw;
}

@media screen and (min-width: 820px) {
  .Card {
    padding: 25px;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    max-width: 800px;
  }

  .Divider {
    max-width: 750px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (max-width: 819px) {
  .Card {
    padding: 25px;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 10px;
    margin-right: 10px;
    max-width: 800px;
  }
  .Divider {
    max-width: 750px;
    width: 40px;
    padding-left: 10px;
    padding-right: 10px;
  }
}

.Footer {
  padding: 5vh;
  padding-bottom: 30vh;
}

.Intro {
  text-align: left;
  padding: 10px;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.LinkedInLogo {
  max-width: 56px;
}

.ResumeLogo {
  max-width: 10vh;
}

.Toolbar {
  margin-left: auto;
  margin-right: auto;
  max-width: 400px;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  background-color: #7fbdf0;
  z-index: 1;
  margin-top: 3px;
  opacity: 1;
}

.ToolbarHeader {
  height: 40px;
  width: 100%;
  background-color: #7fbdf0;
  z-index: 1;
  position: fixed;
  top: 0;
  opacity: 0.8;
}
